import React from 'react';
import logo from './katalis-logo-rev.svg';
import './custom.scss';
import StickyBox from 'react-sticky-box';

function App() {
  
 
  
  return (
    <div className="App">
      <header className="App-header mb-0 text-center">
        <div className="container p-5">
          <img src={logo} className="App-logo" alt="logo" />
        </div>
      </header>
      
      <div id="body-container" className="pt-5 pb-md-5">
        <div className="container text-left text-md-center">
          
          
          <div className="row flex-row-reverse">
            
            <div className="col-md-8 p-5 p-md-3 pt-0 p-0 text-left">
              <blockquote class="blockquote">The key to a more harmonious existence is in the type of interactions we choose to pursue with ourselves and with each other.</blockquote>
  
              <p>Darcy Zerkee is a certified Social & Emotional Intelligence coach who facilitates workshops on Interpersonal Effectiveness.  In doing so, she coaches others in self and social awareness for the purpose of enhancing their day-to-day interactions with others, whether in the home, at the workplace, or in the grocery store lineup. </p>
              
              <p>Darcy has a particular fascination with human behaviour and how our behavioural and emotional reactions actually represent what's going on "behind the scenes" in each of us.  She follows that up with a strong passion for our capability to change these ingrained reactions through better understanding of what makes us tick, and through creating new habits, thereby generating new, more effective behaviour and response.  Out with the old, in with the new.</p>
              
              <p>When Darcy is not busy inspiring harmony amongst humanity, she can often be found going for a rip through the local trails on horseback, where yes, she does occasionally fall off, and where yes, she always, always gets right back on.</p>
              
              <p>Her top 5 personal values (in order) are: Authenticity, Freedom, Integrity, Loyalty, and Kindness.</p>  
              
              <p>Darcy resides in the Comox Valley and currently drives a Mini Cooper.</p>
              
              
              
            </div>
            
            <div className="col-md-4">
              <StickyBox offsetTop={20} offsetBottom={20}>
              
                <div className="row profile-box pt-5 pb-5 pt-md-0 pb-md-0">
                  <div className="col-6 col-md-12">
                    <img src="./darcy.jpg" className="img-fluid round mb-4" alt="Darcy Zerkee - Wavemaker, Katalis Coaching & Consulting"/>
                  </div>
                  <div className="col-6 col-md-12 d-flex flex-column justify-content-center">
                    <h3 class="mb-0">Darcy Zerkee</h3>
                    <p className="h5 mb-3"><em>Wavemaker</em></p>
                    <p className="h5 mb-3"><a href="tel:_12508976850"><strong>250.897.6850</strong></a></p>
                    
                    <a
                        className="btn btn-primary email-link mb-md-5"
                        href="mailto:darcy@kataliscoaching.ca"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Email Darcy &raquo;
                      </a> 
                  </div>
                </div>
                
                
                
                  
                  
              
            </StickyBox>
            </div>
            
            
          </div>
        </div>
      </div>
      <div className="footer text-center">
         <p className="mt-5 mb-5">
                &copy; {(new Date().getFullYear())} Katalis Coaching  & Consulting 
              </p>
      </div>
    </div>
  );
}

export default App;
